import _ from "lodash";
import { store } from "../redux/store";
import {
  botTyping,
  dispatchChatMessage,
  disableChatInput,
  reInitializeChat,
  liveChatQueue,
  connectToAgent,
  connectToLex,
  updateAssistStatus,
  showUrgentInfo,
  handleLauncherAction,
  updateThumbsStatus,
  updateUserStatus,
  shownHistoryStatus,
} from "../redux/chatbotSlice";
import {
  hasQuickReplies,
  mapAsLexQuickReply,
  mapTNPSQuickReply,
} from "../helper/responseChecker";
import { encodeResponseURL } from "../helper/optimizeTools";
import { getPlatform, isOnMobile } from "../helper/BrowserHelper";
import commonConstants from "../helper/constant";
import toolConfig from "../config/toolConfig";
import cmsConfig from "../config/cmsConfig";
import uniqid from "uniqid";
import axios from "axios";
import "amazon-connect-chatjs";
import Cookies from "js-cookie";

let connectionClosed = false;
let isUserTyping = false;
let showWelcomeMessage = false;

let lexSession: any = null;
let isTransferChat = false;
let initUserQuestion = commonConstants.utterance.defaultQuestion;
const MAX_RETRY = 2;
let currentRetry = 0;
// heartbeat variables
let contactId = "";
let heartbeatStatus = false;
let heartbeatTimer: NodeJS.Timeout | null = null;
// for cookie
let cookieContent = {
  Username: "",
  DisplayName: "",
};
let userName = "";
let displayName = "";

export const initilaizeLexConnection = (isRestart = false) => {
  let { initQuestion, msisdn, pageCategory, awsConnect } =
    store.getState().chatbot;

  if (lexSession === null) {
    // Grab shortcodes
    if (!isRestart && (initQuestion || msisdn)) {
      initUserQuestion = `${initQuestion.split("-").join(" ")}${
        msisdn ? ` ${msisdn}` : ""
      }`;
    }

    // Sales page intents
    if (pageCategory === "sales" && !isOnMobile()) {
      setSalesInitQuestion();
    }

    store.dispatch(botTyping(true));
    // eslint-disable-next-line
    // @ts-ignore
    connect.ChatSession.setGlobalConfig({
      region: awsConnect.region,
      endpoint: awsConnect.zeroRatedPoint,
    });

    connectApiCall();
  }
};

const createchatUserCookie = () => {
  // default to 24 hours if not set from config
  let { awsConnect } = store.getState().chatbot;
  const minutes = parseFloat(awsConnect.cookieLifetimeInMinutes);
  const lifetime = !isNaN(minutes) ? minutes * 60 * 1000 : 24 * 60 * 60 * 1000;
  
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + lifetime);

  const cookieValue = JSON.stringify(cookieContent);

  Cookies.set("chatUser", cookieValue, { expires: expirationDate, path: "/" });
};

const showHistoryMsg = () => {
  lexSession
    .getTranscript({
      maxResults: 100,
      sortOrder: "ASCENDING",
    })
    .then((transcript: any) => {
      let chatHistory = transcript.data.Transcript;
      // Ignore the first three messages and process the rest
      if (chatHistory.length > 3) {
        chatHistory = chatHistory.slice(3);
    }
      let lastMessage = chatHistory[chatHistory.length - 1];
      let responseContent: any;
      let quickReplies: any;

      chatHistory.forEach((chat: any) => {
        const { Content, ContentType, ParticipantRole } = chat;
        
        if (ContentType === "text/plain" && Content) {
          let sender = "bot";
          if(ParticipantRole === "SYSTEM"){
            sender= "bot";
            store.dispatch(connectToAgent(false));
          } 
          if(ParticipantRole === "AGENT") {
            sender= "livechat";
            store.dispatch(connectToAgent(true));
          } 
          if(ParticipantRole === "CUSTOMER") sender= "user";
          let message: any = {};
          
          try {
            responseContent = JSON.parse(Content);
          } catch (e) {
            responseContent = Content;
          }

          if (
            responseContent &&
            typeof responseContent.altMessages === "undefined"
          ) {
            // text bubble --- text conver to html
            let text = responseContent
              .replace(/<thumbs\/?>/gi, "")
              .replace(/<rtenable\/?>/gi, "")
              .replace(/<taenable\/?>/gi, "")
              .replace(/<rtdisable\/?>/gi, "");

            let showdown = require("showdown");
            let converter = new showdown.Converter();
            message.text = converter.makeHtml(text);
          }

          // Handle the last message separately: buttons
          if (lastMessage && lastMessage.ContentType === "text/plain") {
            let lastMessageContent: any = {};
            try {
              lastMessageContent = JSON.parse(lastMessage.Content);
            } catch (e) {
              lastMessageContent = lastMessage.Content;
            }

            if (lastMessageContent.responseCard) {
              const responseCard =
                lastMessageContent.responseCard.genericAttachments[0];

              if (responseCard && responseCard.buttons) {
                quickReplies = responseCard.buttons;
                if (quickReplies[0].value === "tnps") {
                  quickReplies = mapTNPSQuickReply();
                } else if (
                  responseCard.subtitle &&
                  responseCard.subtitle === "disable_input"
                ) {
                  quickReplies = mapAsLexQuickReply(quickReplies, "true");
                } else {
                  quickReplies = mapAsLexQuickReply(quickReplies, "false");
                }
              }
              message.quick_replies = quickReplies;
            }
          }

          showLexMessage(sender, message);
        }
      });

      store.dispatch(botTyping(false));
    });
};

const axiosInstance = axios.create();
const connectApiCall = () => {
  let { awsConnect } = store.getState().chatbot;
  let token = Cookies.get("x-authorization") ?? "";

  // if cookie exist && same contactId : old user
  if (Cookies.get("chatUser")) {
    const userCookieString = Cookies.get("chatUser");
    if (userCookieString) {
      const userCookie = JSON.parse(userCookieString);
      userName = userCookie.Username;
      displayName = userCookie.DisplayName;
    }
    store.dispatch(updateUserStatus(true));
  } else {
    userName = "AICUser_" + Date.now();
    displayName = "User_" + Date.now();
    store.dispatch(updateUserStatus(false));
  }

  let initiateChatRequest = {
    ContactFlowId: awsConnect.contactFlowId,
    InstanceId: awsConnect.instanceId,
    Attributes: {
      userSource: "web",
      initPrompt: "initPrompt",
      isInApp: getPlatform() === "App" ? "true" : "false",
      isLoggedIn: isLoggedInUser() ? "true" : "false",
      isOnMobile: isOnMobile() ? "true" : "false",
      userToken: token,
      userPlatform: getPlatform(),
      hostPage: window.location.href.split("?")[0].replace(/^https?:\/\//i, ""),
    },
    Username: userName,
    ParticipantDetails: {
      DisplayName: displayName,
    },
  };

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    () => {
      // Retry with response error
      currentRetry++;
      if (currentRetry <= MAX_RETRY) {
        connectApiCall();
      }
    }
  );

  axiosInstance({
    url: awsConnect.chatApiEndpoint,
    method: "post",
    data: JSON.stringify(initiateChatRequest),
  })
    .then((result) => {
      let chatDetails;
      let start_chat_result = result.data.data.start_chat_result;
      chatDetails = {
        contactId: start_chat_result.ContactId,
        participantId: start_chat_result.ParticipantId,
        participantToken: start_chat_result.ParticipantToken,
      };

      // eslint-disable-next-line
      // @ts-ignore
      lexSession = connect.ChatSession.create({
        chatDetails: chatDetails,
        type: "CUSTOMER",
      });
      contactId = result.data.data.start_chat_result.ContactId;

      // if no cookie, create one
      if (!Cookies.get("chatUser")) {
        // store cookie content
        cookieContent = {
          Username: userName,
          DisplayName: displayName,
        };
        createchatUserCookie();
      }
    })
    .catch(() => {
      if (currentRetry > MAX_RETRY) {
        showLexMessage("bot", { text: getToolConfig().lex.errorMsg });
      }
    })
    .then(() => {
      lexSession.connect().then(
        (response: any) => {
          return response;
        },
        (error: any) => {
          if (error.connectSuccess === false && error._debug._debug.statusCode === 403){
            Cookies.remove("chatUser");
            connectApiCall();
          } else {
            console.log("Unsuccessful connection " + JSON.stringify(error));
          }
        }
      );

      lexSession.onConnectionEstablished(() => {
        // Show welcome messages
        let welcomeMsgArray = cmsConfig.welcomeMessages;
        if (!showWelcomeMessage) {
          if (typeof welcomeMsgArray === "string") {
            // Convert the string to an array
            welcomeMsgArray = [welcomeMsgArray];
          }

          // Ensure welcomeMsgArray is an array before processing
          if (Array.isArray(welcomeMsgArray) && welcomeMsgArray.length > 0) {
            welcomeMsgArray.forEach((msg) => {
              showLexMessage("bot", { text: msg });
            });
          }
        }
        showWelcomeMessage = true;

        if (!heartbeatStatus) {
          triggerHeartBeat(contactId);
        }

        const { isOldUser, historyShown } = store.getState().chatbot;
        if (isOldUser && !historyShown) {
          showHistoryMsg();
          store.dispatch(shownHistoryStatus(true));
        }
        if (!isOldUser) {
          store.dispatch(botTyping(true));
        }
      });

      lexSession.onMessage((message: any) => {
        // QnA bot
        if (
          message.data.ParticipantRole ===
            commonConstants.livechat.participantRole.system &&
          !store.getState().chatbot.isConnectToAgent
        ) {
          // handling response
          handleResponse(message.data.Content);
        }
        // live chat
        handleLiveChatResponse(message);
      });

      lexSession.onTyping((typingEvent: any) => {
        if (
          typingEvent.data.ParticipantRole ===
          commonConstants.livechat.participantRole.agent
        ) {
          store.dispatch(botTyping(true));
        }
      });

      lexSession.onConnectionBroken(() => {
        showLexMessage("bot", { text: "Connection broken" });
        closeChat();
      });

      lexSession.onEnded(() => {
        store.dispatch(updateUserStatus(false));
        Cookies.remove("chatUser");
      });
    });
};

const triggerHeartBeat = (contactId: string) => {
  heartbeatStatus = true;
  let { isChatActive, awsConnect } = store.getState().chatbot;
  const data = { contactId: contactId };

  if (isChatActive) {
    heartbeatTimer = setInterval(() => {
      axios({
        url: `${awsConnect.chatApiEndpoint}/heartbeat`,
        method: "post",
        data: JSON.stringify(data),
      })
        .then(() => {})
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            if (heartbeatTimer) clearInterval(heartbeatTimer);
            heartbeatStatus = false;
          }
        });
    }, 60 * 1000);
  }
};

const setSalesInitQuestion = () => {
  const today = new Date();
  const currentDay = today.getDay();
  const currentTime = today.getHours();
  const cmsConfig = getToolConfig();
  if (currentDay >= 1 && currentDay <= 5) {
    //weekday
    const weekdaysHours = cmsConfig.sales.weekdaysHours.split("-");
    const weekdaysStartHour = parseInt(weekdaysHours[0]);
    const weekdaysEndHour = parseInt(weekdaysHours[1]);
    if (currentTime >= weekdaysStartHour && currentTime < weekdaysEndHour) {
      initUserQuestion = cmsConfig.sales.salesLiveIntent;
    }
  } else if (currentDay === 0 || currentDay === 6) {
    //weekend
    const weekendsHours = cmsConfig.sales.weekendsHours.split("-");
    const weekendsStartHour = parseInt(weekendsHours[0]);
    const weekendsEndHour = parseInt(weekendsHours[1]);
    if (currentTime >= weekendsStartHour && currentTime < weekendsEndHour) {
      initUserQuestion = cmsConfig.sales.salesLiveIntent;
    }
  } else {
    initUserQuestion = cmsConfig.sales.salesLeaveIntent;
  }
};

const handleLiveChatResponse = (message: any) => {
  // agent joined, show welcome message
  if (
    message.data.Type === commonConstants.livechat.dataType.event &&
    message.data.ParticipantRole ===
      commonConstants.livechat.participantRole.agent &&
    message.data.ContentType ===
      commonConstants.livechat.eventType.agentJoined &&
    !store.getState().chatbot.isConnectToAgent
  ) {
    let welcomeMsg = getToolConfig().livechatwelcomemsg;
    welcomeMsg = welcomeMsg.replace(/<agentName>/g, message.data.DisplayName);
    showLexMessage("livechat", { text: encodeResponseURL(welcomeMsg) });
    store.dispatch(connectToAgent(true));
    store.dispatch(liveChatQueue(false));
  }

  // transfer the chat
  if (
    message.data.Type === commonConstants.livechat.dataType.event &&
    message.data.ContentType ===
      commonConstants.livechat.eventType.transferSucceeded
  ) {
    isTransferChat = true;
    store.dispatch(connectToAgent(false));
    store.dispatch(liveChatQueue(false));
  }

  if (
    // transferring, not end the chat
    message.data.Type === commonConstants.livechat.dataType.event &&
    message.data.ParticipantRole ===
      commonConstants.livechat.participantRole.agent &&
    message.data.ContentType === commonConstants.livechat.eventType.agentLeft &&
    isTransferChat
  ) {
    isTransferChat = false;
    store.dispatch(liveChatQueue(true));
  } else if (
    // agent left, show TNPS
    message.data.Type === commonConstants.livechat.dataType.event &&
    message.data.ParticipantRole ===
      commonConstants.livechat.participantRole.agent &&
    message.data.ContentType === commonConstants.livechat.eventType.agentLeft &&
    !isTransferChat
  ) {
    store.dispatch(connectToAgent(false));
    store.dispatch(liveChatQueue(false));
  }

  if (message.data.Content && store.getState().chatbot.isConnectToAgent) {
    if (
      message.data.ParticipantRole ===
      commonConstants.livechat.participantRole.agent
    ) {
      showLexMessage("livechat", {
        text: encodeResponseURL(message.data.Content),
      });
      playNotificationSound();
    }
  }

  // chat ended
  if (
    message.data.Type === commonConstants.livechat.dataType.event &&
    message.data.ContentType === commonConstants.livechat.eventType.chatEnded
  ) {
    closeChat();
    if (heartbeatTimer) clearInterval(heartbeatTimer);
    heartbeatStatus = false;
  }
};

const playNotificationSound = () => {
  const playButton = document.getElementById("playSoundButton");
  if (playButton) {
    playButton.click();
  }
};

const handleResponse = (responseContent: any) => {
  let text = undefined;
  let responseCard = undefined;
  let quickReplies = undefined;

  let showdown = require("showdown");
  let converter = new showdown.Converter();
  try {
    responseContent = JSON.parse(responseContent);
  } catch (e) {
    // ignore me
  }

  if (/^initPrompt/.test(responseContent)) {
    //connect to Lex
    store.dispatch(connectToLex(true));
    if (store.getState().chatbot.userMessage === "") {
      sendLexMessage("user", initUserQuestion, false);
    } else {
      sendLexMessage("user", store.getState().chatbot.userMessage, false);
    }
    return;
  }

  if (/^\^ESCAPE/.test(responseContent)) {
    return; //ignore ESCAPE message
  }

  // live chat queue
  if (/qid:lcd.queue/.test(responseContent)) {
    store.dispatch(liveChatQueue(true));
  }

  if (responseContent && typeof responseContent.altMessages === "undefined") {
    // first response - text bubble
    text = responseContent
      .replace(/<thumbs\/?>/gi, "")
      .replace(/<rtenable\/?>/gi, "")
      .replace(/<taenable\/?>/gi, "")
      .replace(/<rtdisable\/?>/gi, "");
    text = converter.makeHtml(text);
  }

  if (responseContent && responseContent.altMessages) {
    // new livechat tnps flow
    if (!/qid:/.test(responseContent.altMessages.markdown)) {
      text = converter.makeHtml(responseContent.altMessages.markdown);
    }

    if (responseContent.taenable && responseContent.taenable === "true") {
      // enable tobi assist
      store.dispatch(
        updateAssistStatus({
          assistValue: "",
          isFirstAssist: true,
          isHideAssist: true,
        })
      );
    }

    if (responseContent.thumbs && responseContent.thumbs === "true") {
      // show thumbs
      store.dispatch(updateThumbsStatus(true));
    }

    if (responseContent.responseCard) {
      // second response
      responseCard = responseContent.responseCard.genericAttachments[0];

      // quick replies - buttons
      if (responseCard.buttons) {
        quickReplies = responseCard.buttons;
        if (quickReplies[0].value === "tnps") {
          quickReplies = mapTNPSQuickReply();
        } else if (
          responseCard.subtitle &&
          responseCard.subtitle === "disable_input"
        ) {
          quickReplies = mapAsLexQuickReply(quickReplies, "true");
        } else {
          quickReplies = mapAsLexQuickReply(quickReplies, "false");
        }
      }

      // images
      if (responseCard.imageUrl) {
        text = '<img src="' + responseCard.imageUrl + '" />';
      }
    }
  }

  showLexMessage("bot", { text: text, quick_replies: quickReplies });
};

export const splitTextIntoParagraphs = (htmlText: string): string[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlText, "text/html");

  const paragraphs: string[] = [];
  const inlineTags = ["A", "STRONG", "EM", "B", "I", "U", "SPAN", "BR", "IMG"];

  Array.from(doc.body.childNodes).forEach((node) => {
    // element node
    if (node.nodeType === Node.ELEMENT_NODE) {
      // not an inline tag
      if (!inlineTags.includes((node as HTMLElement).tagName)) {
        paragraphs.push((node as HTMLElement).outerHTML);
      } else {
        // inline tag, append its content to the last paragraph
        const outerHTML = (node as HTMLElement).outerHTML;
        if (paragraphs.length > 0) {
          // preserve spaces
          const lastParagraph = paragraphs[paragraphs.length - 1];
          paragraphs[paragraphs.length - 1] =
            lastParagraph.trimEnd() + " " + outerHTML + " ";
        } else {
          paragraphs.push(`<p>${outerHTML}</p>`);
        }
      }
    } else if (
      node.nodeType === Node.TEXT_NODE &&
      node.textContent!.trim().length > 0
    ) {
      // text node
      if (paragraphs.length > 0) {
        paragraphs[paragraphs.length - 1] += node.textContent!.trim();
      } else {
        paragraphs.push(`<p>${node.textContent!.trim()}</p>`);
      }
    }
  });

  // If no tags found, wrap the entire content in a <p> tag
  if (paragraphs.length === 0 && doc.body.textContent!.trim().length > 0) {
    paragraphs.push(`<p>${doc.body.innerHTML}</p>`);
  } else if (paragraphs.length > 0) {
    paragraphs[paragraphs.length - 1] += "</p>";
  }

  return paragraphs;
};

export const showLexMessage = (
  sender: string,
  message: any,
  prefixType?: any
) => {
  store.dispatch(disableChatInput(false));

  if (message) {
    if (
      message.quick_replies &&
      message.quick_replies[0].disableinput === "true"
    ) {
      store.dispatch(disableChatInput(true));
    }

    if (message.text) {
      if (prefixType) {
        message.text = removePrefixFromMsg(message.text, prefixType);
      }

      // Split text into paragraphs
      const paragraphs = splitTextIntoParagraphs(message.text);

      // Dispatch each paragraph as a separate chat message
      paragraphs.forEach((paragraph) => {
        store.dispatch(
          dispatchChatMessage({
            chatMessage: {
              type: "message",
              sender: sender,
              message: { text: paragraph }, // Wrap each paragraph in an object
              chatId: uniqid(),
              isGroupMsg: false,
            },
            quickReply: hasQuickReplies({ text: paragraph }),
          })
        );
      });
    }

    if (message.quick_replies) {
      store.dispatch(
        dispatchChatMessage({
          chatMessage: {
            type: "message",
            sender: sender,
            message: {},
            chatId: uniqid(),
            isGroupMsg: false,
          },
          quickReply: hasQuickReplies(message),
        })
      );
    }
    store.dispatch(botTyping(false));
  }
};

export const sendLexMessage = (
  sender: string,
  message: any,
  isDisplayOnUI = true,
  prefixType = ""
) => {
  store.dispatch(disableChatInput(false));
  if (message) {
    message = updateMsgWithPrefix(message, prefixType);
    lexSession.controller.sendMessage({
      message: message,
      contentType: "text/plain",
    });
    if (isDisplayOnUI === true) {
      showLexMessage(sender, { text: message }, prefixType);
    }
    if (!store.getState().chatbot.isConnectToAgent) {
      store.dispatch(botTyping(true));
    }
  }
};

const updateMsgWithPrefix = (message: string, prefixType: string) => {
  if (prefixType === "assist") {
    message = `!${message}`;
  }
  return message;
};

const removePrefixFromMsg = (message: string, prefixType: string) => {
  if (prefixType === "assist") {
    message = message.substring(1);
  }
  return message;
};

export const handleCloseChatPanel = () => {
  let chatBotState = store.getState().chatbot;
  // close chat panel when in live chat queue or connected,send disconnectParticipant
  if (
    chatBotState.isChatActive &&
    (chatBotState.isInChatQueue || chatBotState.isConnectToAgent)
  ) {
    lexSession.controller.disconnectParticipant();
    connectionClosed = true;
    store.dispatch(connectToAgent(false));
    store.dispatch(liveChatQueue(false));
  }
  store.dispatch(handleLauncherAction(false));
  store.dispatch(updateThumbsStatus(false));
  // stop heartbeat
  if (heartbeatTimer) clearInterval(heartbeatTimer);
  heartbeatStatus = false;
};

export const handleRelaunchAction = () => {
  store.dispatch(handleLauncherAction(true));
  let chatBotState = store.getState().chatbot;
  // restart chat if connection not exist
  if (chatBotState.isChatActive && connectionClosed) {
    restartLexChatInstance();
    return;
  }

  // send 'restart' if connection not ended
  if (
    chatBotState.isChatActive &&
    chatBotState.chatMessage.length > 0 &&
    !connectionClosed
  ) {
    let errorResponse = formatErrorResponse("restart");
    showChatNotice(errorResponse);

    sendLexMessage("user", "restart", false);

    store.dispatch(
      updateAssistStatus({
        assistValue: "",
        isFirstAssist: true,
        isHideAssist: true,
      })
    );
    //trigger heartBeat
    triggerHeartBeat(contactId);
  }
};

export const getToolConfig = () => {
  return toolConfig;
};

export const setUserTyping = (isTyping: boolean) => {
  if (isUserTyping !== isTyping) {
    isUserTyping = isTyping;
    if (store.getState().chatbot.isConnectToAgent && isUserTyping) {
      lexSession.controller.sendEvent({
        contentType: "application/vnd.amazonaws.connect.event.typing",
      });
    }
  }
};

export const showChatNotice = (notice: any) => {
  if (notice) {
    store.dispatch(
      dispatchChatMessage({
        chatMessage: {
          type: "notice",
          sender: "notice",
          message: notice,
          chatId: uniqid(),
          isGroupMsg: false,
        },
        quickReply: hasQuickReplies(notice),
      })
    );
  }
};

const showRestartChatText = (isFatalError: string) => {
  return !!(cmsConfig.vfoBotRestartChatLabel && isFatalError);
};

export const formatErrorResponse = (reason = "default") => {
  let errorResponse = _.find(cmsConfig.vfoWebServiceErrors, {
    vfoWebServiceErrorMessage: reason,
  });

  if (errorResponse && showRestartChatText(errorResponse.vfoIsFatalFormError)) {
    errorResponse.quick_replies = [
      {
        content_type: "text",
        title: cmsConfig.vfoBotRestartChatLabel,
        payload: cmsConfig.vfoBotRestartChatLabel,
        action: "restart",
      },
    ];
  }

  if (errorResponse && errorResponse.vfoErrorMessageText) {
    let today = new Date();
    let currentTime =
      timeToDoubleNumber(today.getHours()) +
      ":" +
      timeToDoubleNumber(today.getMinutes());
    let updateErrorResponseWithTime = errorResponse.vfoErrorMessageText.replace(
      /<time>/g,
      currentTime
    );
    errorResponse.text = updateErrorResponseWithTime;
  }

  return errorResponse ? errorResponse : "";
};

const timeToDoubleNumber = (time: number) => {
  return time < 10 ? "0" + time : "" + time;
};

const closeChat = (reason?: string) => {
  if (connectionClosed) {
    return;
  }
  connectionClosed = true;

  let errorResponse = _.pick(formatErrorResponse(reason), [
    "text",
    "quick_replies",
  ]);
  //clear inputarea and trigger onchange event for react 16
  let inputArea = document.querySelector(".chat-footer .chat-input");
  if (inputArea instanceof HTMLTextAreaElement) {
    inputArea.value = "";
    inputArea.dispatchEvent(new Event("input", { bubbles: true }));
  }

  store.dispatch(updateAssistStatus({ isHideAssist: true }));
  store.dispatch(disableChatInput(true));
  store.dispatch(showUrgentInfo(false));
  store.dispatch(updateThumbsStatus(false));

  if (errorResponse) {
    showChatNotice(errorResponse);
  }
};

export const restartLexChatInstance = () => {
  const { awsConnect } = store.getState().chatbot;
  store.dispatch(reInitializeChat({ awsConnect, isChatActive: true }));
  lexSession = null;
  connectionClosed = false;
  showWelcomeMessage = false;
  initUserQuestion = commonConstants.utterance.defaultQuestion;
  const input = document.querySelector(".chat-input");
  if (input) {
    input.removeAttribute("style");
  }

  // clear heartbeat timer
  if (heartbeatTimer) clearInterval(heartbeatTimer);
  heartbeatStatus = false;

  initilaizeLexConnection(true);
};

const isLoggedInUser = () => {
  return (
    !_.isUndefined(Cookies.get("x-authorization")) || getPlatform() === "App"
  );
};

export const isConnectionClosed = () => {
  return connectionClosed;
};

export const lexSessionValue = () => {
  return lexSession;
};
