import React, { useEffect, useState, useCallback } from "react";
import { logger } from "./config/Config";
import ChatBot from "./component/Chatbot";
import { store } from "./redux/store";
import { updateEnv } from "./redux/chatbotSlice";

interface IClientConfig {
  chatApiEndpoint: string;
  region: string;
  contactFlowId: string;
  instanceId: string;
  zeroRatedPoint: string;
  adminApiUrl: string;
  environment: string;
  cookieLifetimeInMinutes: string;
}

type ConfigFunction = () => IClientConfig;

declare global {
  interface Window {
    get_client_config: ConfigFunction;
  }
}

const App: React.FC = () => {
  const [environment, setEnvironment] = useState("");
  const configLoaded = useCallback(() => {
    logger.info("configLoaded");
    const config = window.get_client_config();
    setEnvironment(config.environment);
    logger.info(environment);
    store.dispatch(
      updateEnv({
        chatApiEndpoint: config.chatApiEndpoint,
        region: config.region,
        contactFlowId: config.contactFlowId,
        instanceId: config.instanceId,
        zeroRatedPoint: config.zeroRatedPoint,
        adminApiUrl: config.adminApiUrl,
        environment: config.environment,
        cookieLifetimeInMinutes: config.cookieLifetimeInMinutes,
      })
    );
  }, [environment]);

  useEffect(() => {
    const script = document.createElement("script");
    let confURL = process.env["REACT_APP_CONFIG_URL"]; // eslint-disable-line @typescript-eslint/dot-notation
    if (!confURL) {
      confURL = "Config.js";
    }
    script.src = confURL;
    script.async = true;
    script.onload = () => configLoaded();
    document.body.appendChild(script);
  }, [configLoaded]);

  /** As One Assistant is in app, comment this out temporally **/
  // useEffect(() => {
  //   const handleBeforeUnload = (e: any) => {
  //     e.preventDefault();
  //     e.returnValue = "Are you sure you want to close the chat?"; 
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [])

  return (
    <div className="App">
      <ChatBot />
    </div>
  );
};

export default App;
