export const hasImage = (message: any): boolean => {
  return !!(
    message.attachment &&
    "image" === message.attachment.type.trim() &&
    message.attachment.payload.url
  );
};

export const hasQuickReplies = (message: any): any[] => {
  if (message.quick_replies && message.quick_replies.length > 0) {
    return message.quick_replies;
  } else if (
    message.attachment &&
    "qrdButtons" === message.attachment.type.trim() &&
    message.attachment.payload.buttons.length > 0
  ) {
    return mapAsQuickReply(message.attachment.payload.buttons);
  }
  return [];
};

export const hasCountDownTimer = (message: any): boolean => {
  return !!(
    message.attachment &&
    "cdtimer" === message.attachment.type.trim() &&
    message.attachment.payload.countfrom
  );
};

const mapAsQuickReply = (buttons: any[]): any[] => {
  return buttons.map((button) => {
    return { content_type: "text", payload: button, title: button };
  });
};

export const mapAsLexQuickReply = (
  buttons: any[],
  disableinput = "false"
): any[] => {
  return buttons.map((button) => {
    return {
      content_type: "text",
      payload: button.value,
      title: button.text,
      disableinput: disableinput,
      istnps: button.istnps === "true" ? "true" : "false",
    };
  });
};

export const mapTNPSQuickReply = (): any[] => {
  let tnpsScores = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  let tnpsButtons = tnpsScores.map((score) => ({
    text: score,
    value: score,
    istnps: "true",
  }));

  return mapAsLexQuickReply(tnpsButtons, "true");
};

export const hasLexQrdButtons = (message: any): boolean => {
  return !!(message.quick_replies && message.quick_replies.length > 0);
};
