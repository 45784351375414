import React, { useEffect } from "react";
import { useAppDispatch } from "../../redux/hooks";
import BubbleWrapper from "../BubbleWrapper";
import { sendLexMessage } from "../../services/socketClient";
import commonConstants from "../../helper/constant";
import {
  updateAssistStatus,
  updateThumbsStatus,
} from "../../redux/chatbotSlice";

const Thumbs: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      updateAssistStatus({
        assistValue: "",
        isFirstAssist: true,
        isHideAssist: true,
      })
    );
  }, [dispatch]);

  const handleClick = (thumbsType: string): void => {
    if (thumbsType === "up") {
      sendLexMessage("user", commonConstants.utterance.thumbsUp);
    } else if (thumbsType === "down") {
      sendLexMessage("user", commonConstants.utterance.thumbsDown);
    }
    dispatch(updateThumbsStatus(false));
  };

  return (
    <BubbleWrapper wrapperClass="chat-thumbs">
      <div className="thumbs-wrapper">
        <p className="thumbs-text">Was this information helpful?</p>
        <p className="thumbs-button-wrapper">
          <span className="thumbs" onClick={() => handleClick("up")}>
            <svg
              focusable="false"
              aria-hidden="true"
              className="icon thumbs-up"
            >
              <use xlinkHref="#icon-tobi-thumbs-up" />
            </svg>
            Yes
          </span>
          <span className="thumbs" onClick={() => handleClick("down")}>
            <svg
              focusable="false"
              aria-hidden="true"
              className="icon thumbs-down"
            >
              <use xlinkHref="#icon-tobi-thumbs-down" />
            </svg>
            No
          </span>
        </p>
      </div>
    </BubbleWrapper>
  );
};

export default Thumbs;
